import React, { useEffect, useState } from 'react'

import { useMutation } from 'react-query'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'

import DummyHomePage from 'containers/ViewCompany/DummyHomePage'
import ReasonToJoinModal from 'containers/ViewCompany/ReasonToJoinModal'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import DropDownMenu from 'global/DropDownMenu'
import Loading from 'global/Loading'
import { useModal } from 'global/Modal'
import { useAppSelector, useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { loadGroup, selectGroup } from 'store/reducers/groupsReducer'

import api, { callApi } from 'utils/api'
import useColorMode from 'utils/hooks/useColorMode'

import UpdateUserNameModal from './UpdateUserNameModal'

interface Props {
  teamSlug?: string
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.layout.nav_bg_color};
  border: ${({ theme }) => theme.border};
`

const IndeterminantTeamPage: React.VFC<Props> = ({ teamSlug: _teamSlug }) => {
  const history = useHistory()
  const location = useLocation()
  const { isMobile } = useColorMode()
  const { showModal } = useModal()
  const [loading, setLoading] = useState(true)

  const teamSlug = useTeamSlug() || _teamSlug
  const { team } = useTeam(teamSlug!)

  const match = useRouteMatch<{ id: string }>(`/${teamSlug}/groups/:id`)
  const groupUuid = match?.params.id

  const group = useAppSelector(selectGroup(groupUuid))

  useEffect(() => {
    groupUuid && loadGroup({ team_slug: teamSlug!, uuid: groupUuid })
  }, [])

  const { isLoggedIn, user, isUserLoaded, reloadUser } = useCurrentUser()

  const joinRequestMutation = useMutation((reason?: string) =>
    callApi(api.requestToJoin, teamSlug!, groupUuid, reason),
  )

  const requested = !!joinRequestMutation.data || team?.join_status === 'requested'
  const joiningGroup = team?.permissions.canViewTeam && groupUuid

  const candidatesInviteLink = useSearchParam('candidates_invite_link')
  const inviteToken = useSearchParam('token')

  const enrichProfileMutation = useMutation(
    (inviteToken: string) => {
      return callApi(api.createViaToken, { token: inviteToken, team_slug: teamSlug })
    },
    {
      onSuccess: ({ data }) => {
        ;(window.location as Location).reload()
      },
      onError: (error) => {
        setLoading(false)
      },
    },
  )

  useEffect(() => {
    if (isUserLoaded && !isLoggedIn) {
      history.push({ pathname: '/login', state: { from: location } })
    } else if (isUserLoaded && isLoggedIn && candidatesInviteLink && inviteToken) {
      enrichProfileMutation.mutate(inviteToken)
    } else {
      setLoading(false)
    }
  }, [history, isLoggedIn, isUserLoaded, location])

  const requestToJoin = (
    reason?: 'portfolio-founder' | 'recruiting' | 'portfolio-company' | 'job-seeker' | 'partner',
  ) => {
    if (!isLoggedIn) {
      return
    } else if (!user.name) {
      showModal(
        (resolve) => (
          <UpdateUserNameModal
            onNameSave={() => joinRequestMutation.mutate(reason)}
            resolve={resolve}
          />
        ),
        'request-join-user-name',
      )
    } else {
      joinRequestMutation.mutateAsync(reason).then(reloadUser)
    }
  }

  const switchAccount = (email: string) => {
    api.switchAccount(email).then(({ data }) => {
      if (data.profile) {
        window.location.reload()
      }
    })
  }

  const handleReasonToJoin = () => {
    showModal(
      (resolve) => (
        <ReasonToJoinModal
          onHide={() => resolve()}
          team={team!}
          onSubmit={(reason?: string) => requestToJoin(reason)}
        />
      ),
      'reason-to-join-modal',
    )
  }

  if (!team) return <Loading className="mt-12" />

  let body = <></>

  if (isLoggedIn) {
    if (joiningGroup) {
      if (group?.added) {
        body = <>You already have access to this group</>
      } else {
        body = (
          <>
            {isMobile && <p className="pb-4">You are logged in as {user.email}</p>}
            <p className="mb-3">You don{`'`}t have access to this group.</p>
            <p className="mb-8">
              Request to join {team.name}
              {`'`}s {group?.name} group or login using a different email address.
            </p>
            <CabalButton
              variant={requested ? 'secondary' : 'primary'}
              working={joinRequestMutation.isLoading}
              disabled={requested}
              onClick={() => requestToJoin()}
              data-testid="request-to-join-cta"
              tooltip={
                requested ? 'We will notify you by email when your request is accepted' : undefined
              }
            >
              {requested ? 'Pending approval' : `Add me`}
            </CabalButton>
          </>
        )
      }
    } else {
      body = (
        <>
          <p className="mb-3">You don{`'`}t have access to view this workspace.</p>
          <p className="mb-8">
            You’re logged in as {user.email}. Make sure you’re using the correct email address or
            request to join this workspace.
          </p>
          <CabalButton
            variant={requested ? 'secondary' : 'primary'}
            disabled={requested}
            working={joinRequestMutation.isLoading}
            onClick={team.enable_portfolio ? () => handleReasonToJoin() : () => requestToJoin()}
            data-testid="request-to-join-cta"
            tooltip={
              requested ? 'We will notify you by email when your request is accepted' : undefined
            }
          >
            {requested ? 'Pending approval' : `Request to join`}
          </CabalButton>
        </>
      )
    }
  }

  const switchAccountButton =
    isLoggedIn && user.user_emails.length > 1 ? (
      <DropDownMenu
        menuItems={
          user.user_emails?.map((secondaryEmail) => {
            if (user.email === secondaryEmail) return null

            return {
              label: secondaryEmail,
              onSelect: () => switchAccount(secondaryEmail),
            }
          }) || []
        }
        trigger={
          <CabalButton
            leftIcon={<i className="far fa-repeat fa-fw mr-3" />}
            variant="secondary"
            className="mt-6"
          >
            Switch Account
          </CabalButton>
        }
      />
    ) : null

  if (loading) {
    return <Loading size={12} />
  }

  return (
    <div className="relative">
      <div className="flex justify-center absolute top-8 left-0 right-0 z-20">
        <Wrapper className="max-w-2xl flex h-5/6 justify-center items-center p-4 rounded-lg drop-shadow-lg">
          <div className="text-center">
            <Avatar src={team.logo} name={team.name} />
            <p className="block py-6 font-bold text-xl">{team.name} </p>
            <div className="max-w-lg">{body}</div>
            {!(joiningGroup && group?.added) && switchAccountButton}
          </div>
        </Wrapper>
      </div>
      <div>
        <DummyHomePage team={team} />
      </div>
    </div>
  )
}

export default IndeterminantTeamPage
