import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'
import { icon } from '@fortawesome/fontawesome-svg-core'

import compact from 'lodash/compact'
import moment from 'moment'
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import styled from 'styled-components'
import { ArrayParam, BooleanParam, StringParam, useQueryParams } from 'use-query-params'

import { useComposer } from 'components/Composer'
import CrmIcon from 'components/CrmIcon'
import { EditListModal } from 'components/EditList'
import ListSharingSettings from 'components/EditList/EditListAccess/ListSharingSettings'
import { renderFilters } from 'components/Filters'
import ConnectionsWidget from 'containers/CompanyPage/ConnectionsWidget'
import { NewItemModal } from 'containers/ItemDetailPage/NewItemModal'
import PageHeader from 'containers/ItemDetailPage/PageHeader'
import AddCandidate from 'containers/ListIndexView/TalentList/PeopleList/AddCandidate'
import AdvisorListView from 'containers/ListView/Companies/AdvisorListView'
import AllCandidateFilters from 'containers/ListView/Companies/AllCandidateFilters'
import CommentsAndActivity from 'containers/ListView/Companies/CommentsAndActivity'
import { TALENT_FILTERS } from 'containers/ListView/members'
import ListSharedModal from 'containers/MemberAddList/ListSharedModal'
import ImportCompaniesModal from 'containers/Portfolio/AdminView/CompaniesTabV1/ImportCompaniesModal'
import { useAccessControl } from 'global/AccessControl'
import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import EmptyState from 'global/EmptyState'
import Loading from 'global/Loading'
import { RenderModal, useModal } from 'global/Modal/Context'
import Typography from 'global/Typography'
import { useCurrentUser, useCurrentUserSettings } from 'store/hooks'
import { useTeamSlug } from 'store/hooks'
import GridWrapper from 'ui-components/GridWrapper'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import { cn } from 'utils/styles'
import { replaceVariables } from 'utils/templates'
import { CompaniesFiltersType, CompanySlugParam, ListTypeModel, Team } from 'utils/types'

import CompanyCard from './CompanyCard'
import Filters from './Filters'
import StatusCards from './StatusCards'

interface Props {
  listUuid?: string
}

interface WidthWrapperProps {
  isTalent: boolean
}

const WidthWrapper = styled.div<WidthWrapperProps>`
  max-width: ${(props) => (props.isTalent ? '974px' : '630px')};
  flex-grow: 1;
`

const Companies: React.FC<Props> = (props) => {
  const { listUuid } = props
  const { showModal } = useModal()
  const { state: locationState } = useLocation<{ shared_with: Team } | undefined>()
  const { company_slug, list_id } = useParams<CompanySlugParam & { list_id: string }>()
  const advisorUuidFromSearch = useSearchParam('advisor')
  const companyParam = useSearchParam('company')
  const sender_uuid = useSearchParam('s')
  const global_person_id = useSearchParam('gpid')
  const person_name = useSearchParam('pn')
  const li_url = useSearchParam('li')
  const advisor_uuid = useSearchParam('a')
  const requestable_id = useSearchParam('rid')
  const edit = window.location.hash === '#edit'
  const viewOnLoad =
    window.location.hash === '#grid'
      ? 'grid'
      : window.location.hash === '#list'
      ? 'list'
      : undefined
  const likes = useSearchParam('likes') || false
  const listParam = useSearchParam('list')
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee, isAdmin } = useAccessControl(company_slug)
  const { compose } = useComposer(company_slug)
  const [showResultsLoading, setShowResultsLoading] = useState(true)
  const initialIndexingCompletedRef = useRef<string | undefined>(undefined)
  const history = useHistory()
  const { user } = useCurrentUser()
  const [showAddItem, setShowAddItem] = useState(false)
  const [exportLink, setExportLink] = useState(false)
  const [view, setView] = useState<'list' | 'grid'>(viewOnLoad || 'grid')
  const [firstRun, setFirstRun] = useState(true)
  const defaultFilter: CompaniesFiltersType = {}
  const [selectedTalentPeople, setSelectedTalentPeople] = useState<any[]>([])
  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  if (!!advisorUuidFromSearch) {
    defaultFilter.advisor_uuids = [advisorUuidFromSearch]
  }

  if (!!companyParam) {
    defaultFilter.search = [decodeURI(companyParam)]
  }

  if (!!likes) {
    defaultFilter.request_filter = 'liked'
  }

  const [talentFiltersValue, setTalentFiltersValue] = useQueryParams({
    query: StringParam,
    function: ArrayParam,
    level: ArrayParam,
    location: ArrayParam,
    function_preference: ArrayParam,
    level_preference: ArrayParam,
    stage_preference: ArrayParam,
    opportunity_type: ArrayParam,
    ideal_company_size: ArrayParam,
    office_preference: ArrayParam,
    status: StringParam,
    member_view: BooleanParam,
  })

  const [renderedTalentFilters, appliedTalentFilters] = renderFilters({
    filters: TALENT_FILTERS,
    value: talentFiltersValue as {
      query?: string
      function?: string[]
      level?: string[]
      location?: string[]
      function_preference?: string[]
      level_preference?: string[]
      stage_preference?: string[]
      opportunity_type?: string[]
      ideal_company_size?: string[]
      office_preference?: string[]
      status?: string
    },
    onChange: (newValues) => {
      setTalentFiltersValue(newValues)
    },
    withPrefix: true,
  })

  const queryParamConfigMap = useMemo(() => {
    return {
      advisor_uuids: ArrayParam,
      search: ArrayParam,
      company_list_uuids: ArrayParam,
      group_uuids: ArrayParam,
      stage_names: ArrayParam,
      portfolio_stages: ArrayParam,
      size: ArrayParam,
      tags: ArrayParam,
      owner_emails: ArrayParam,
      sources: ArrayParam,
      request_filter: StringParam,
      portfolio: BooleanParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const { shared_with: listSharedWith } = locationState || {}
  const [showListSharedModal, setShowListSharedModal] = useState<boolean>(!!listSharedWith)

  const listSlug = list_id && list_id !== 'companies' ? list_id : listParam
  const allCompanies = list_id ? list_id === 'companies' : false

  const setFilters = (updatedFilters: CompaniesFiltersType) => {
    setFilters_(updatedFilters)
  }

  useEffect(() => {
    if (sender_uuid && global_person_id && requestable_id) {
      compose({
        sender_uuid: sender_uuid,
        template_key: 'member_intro_template',
        person_id: global_person_id,
        company_id: requestable_id,
        ready: false,
      })
    } else if (sender_uuid && global_person_id) {
      compose({
        sender_uuid: sender_uuid,
        template_key: 'member_intro_template',
        person_id: global_person_id,
        ready: false,
      })
    } else if (sender_uuid && requestable_id) {
      compose({
        sender_uuid: sender_uuid,
        template_key: 'member_intro_template',
        company_id: requestable_id,
        ready: false,
      })
    }

    if (
      companyParam &&
      person_name &&
      li_url &&
      requestable_id &&
      global_person_id &&
      !sender_uuid &&
      advisor_uuid
    ) {
      const linkedin_url = decodeURI(li_url)
      const clean_person_name = decodeURI(person_name)

      callApi(api.getDefaultTemplate, company_slug, 'intro_template').then((resp) => {
        const template = resp.template
        const replacementVariables = {
          connection_company: decodeURI(companyParam),
          connection_full_name: clean_person_name,
          connection_linkedin_url: linkedin_url,
        }
        const subject = replaceVariables(template.subject, {
          params: replacementVariables,
        })
        const body = replaceVariables(template.body, {
          params: {
            ...replacementVariables,
            connection_full_name: `<a href="${linkedin_url}" target="_blank"
        rel="nofollow noopener">${clean_person_name}</a>`,
          },
        })

        const recipients = advisor_uuid.split(',').map((advisor_uuid) => {
          return { type: 'advisor', value: advisor_uuid, label: '' }
        })

        callApi(api.createRequest, company_slug, {
          company_id: requestable_id,
          request_type: 'connection_intro_queue',
          data: {
            subject: subject,
            body: body,
            req_intros_to: recipients,
            company: decodeURI(companyParam),
            person: {
              uuid: global_person_id,
              name: clean_person_name,
            },
          },
        }).then((request) => {
          compose({ messageUuid: request.message_uuid })
        })
      })
    }
  }, [])

  const {
    dataUpdatedAt: listDataUpdatedAt,
    data: companyListData,
    refetch: refetchList,
    isLoading: listDataIsLoading,
    error: companyListError,
  } = useQuery(
    ['getCompanyList', company_slug, listSlug, talentFiltersValue],
    () => callApi(api.getCompanyList, company_slug, listSlug!, false),
    {
      enabled: !!listSlug,
      refetchInterval: (companyListData) => {
        if (
          companyListData?.company_list?.status?.stale ||
          companyListData?.company_list?.status?.matching_stale ||
          companyListData?.company_list?.status?.dynamic_stale
        ) {
          return 3000
        }

        const results = data?.pages?.flatMap((page) => {
          return page.company_ids?.map((id) => page.company_records[id]) ?? []
        })

        const isEmptyAndRecentlyIndexed =
          (results ?? []).length === 0 &&
          (!companyList?.status?.initial_indexing_completed_at ||
            moment().diff(moment(companyList.status.initial_indexing_completed_at), 'minutes') < 5)

        if (isEmptyAndRecentlyIndexed) {
          return 1500
        }

        return false
      },
      refetchIntervalInBackground: !window.DISABLE_CONNECTION_STATUS_POLLING,
      keepPreviousData: true,
    },
  )

  const companyList = companyListData?.company_list

  let listTypeData: ListTypeModel
  if (companyList) listTypeData = window.LIST_TYPES[companyList.list_type]
  else if (allCompanies) listTypeData = window.LIST_TYPES.companies
  else listTypeData = window.LIST_TYPES.other

  const {
    isLoading: companyDataIsLoading,
    data,
    refetch,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt: companyDataUpdatedAt,
  } = useInfiniteQuery(
    [
      company_slug,
      'opportunityData',
      companyList?.uuid,
      companyList?.status?.stale,
      companyList?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(
        api.getCompanies,
        company_slug, // team slug
        page, // page
        {
          ...filters,
          sources: !!companyList ? [companyList?.uuid] : [],
        }, // filters
        listTypeData?.show_filters && companyList?.is_talent
          ? { talent_filters: talentFiltersValue }
          : {}, // extra params
      ),
    {
      cacheTime: 0,
      refetchInterval: (data) => {
        if (listTypeData?.es || companyList?.default_type_identifier) {
          const results = data?.pages?.flatMap((page) => {
            return page.company_ids?.map((id) => page.company_records[id]) ?? []
          })
          const isEmptyAndRecentlyIndexed =
            (results ?? []).length === 0 &&
            (!companyList?.status?.initial_indexing_completed_at ||
              moment().diff(moment(companyList.status.initial_indexing_completed_at), 'minutes') <
                5)

          if (isEmptyAndRecentlyIndexed) {
            return 1500
          }
        }

        return false
      },
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      onSettled: () => setShowResultsLoading(false),
      keepPreviousData: true,
      enabled: isAdminOrEmployee,
    },
  )

  useEffect(() => {
    if (firstRun && !!companyList) {
      companyList.is_talent && setView('grid')
      companyList.is_all_candidates && setView('list')
      companyList.list_category === 'sales' && setView('list')
      setFirstRun(false)
      return
    }
  }, [firstRun, companyList])

  const companiesRefetch = () => {
    isAdminOrEmployee ? refetch() : setShowResultsLoading(false)
  }

  useEffect(() => {
    if (window.ENV === 'test') {
      if (new Date(companyDataUpdatedAt - 800).getTime() < listDataUpdatedAt) {
        companiesRefetch()
      }
    } else {
      if (companyDataUpdatedAt < listDataUpdatedAt) {
        companiesRefetch()
      }
    }
  }, [companyDataUpdatedAt, listDataUpdatedAt, refetch])

  useEffect(() => {
    if (companyList?.status) {
      const { initial_indexing_completed_at } = companyList?.status || {}

      if (initialIndexingCompletedRef.current === undefined) {
        initialIndexingCompletedRef.current = initial_indexing_completed_at
      }

      if (
        initial_indexing_completed_at &&
        initial_indexing_completed_at != initialIndexingCompletedRef.current
      ) {
        reload()
        initialIndexingCompletedRef.current = initial_indexing_completed_at
      }
    }
  }, [companyList?.status])

  const reload = () => {
    companiesRefetch()
    refetchList()
  }

  const renderEditListModal = useCallback<RenderModal>(
    (resolve) => (
      <EditListModal
        teamSlug={teamSlug}
        onHide={() => resolve(false)}
        reload={reload}
        listUuid={companyList?.uuid}
        onDelete={() => history.push(`/${teamSlug}/lists`)}
        ctaType={companyList?.cta_type}
        enableGatekeeper={companyList?.enable_gatekeeper}
        gatekeeper_id={companyList?.gatekeeper_id}
        dynamicGatekeeper={companyList?.dynamic_gatekeeper}
        isPortfolioList={!!companyList?.portfolio}
      />
    ),
    [teamSlug, reload, companyList, history],
  )

  const renderAddPortfolioCompany = useCallback<RenderModal>(
    (resolve) => (
      <ImportCompaniesModal
        teamSlug={companyList?.owning_team?.slug || ''}
        resolve={() => {
          reload()
          resolve()
        }}
      />
    ),
    [companyList],
  )

  // don't show companies from previous list if a new list is selected
  const companies = useMemo(() => {
    let companiesWithResults: any = []

    // get all the companies with results
    if (data?.pages?.[0]?.sources?.includes(companyList?.uuid) || !companyList?.uuid) {
      companiesWithResults = data?.pages?.flatMap((page) => {
        return page.company_ids.map((id) => page.company_records[id])
      })
    }

    return companiesWithResults
  }, [data, companyList])

  useEffect(() => {
    if (edit && companyList) {
      showModal(renderEditListModal, 'render_edit_list_modal')
      const newUrl = window.location.href.replace(/#edit$/, '')
      window.history.replaceState({}, '', newUrl)
    }
  }, [edit, showModal, renderEditListModal, companyList])

  const renderShareListModal: RenderModal = (resolve) => (
    <ListSharingSettings
      teamSlug={teamSlug}
      resolve={() => resolve(false)}
      uuid={companyList?.uuid}
      reload={reload}
      header={`Share ${companyList?.name}`}
    />
  )

  const setRenderedFilters = () => {
    if (listTypeData?.show_filters) {
      if (isAdminOrEmployee) {
        if (companyList?.is_talent) {
          return (
            <AllCandidateFilters
              talentFiltersValue={talentFiltersValue}
              setTalentFiltersValue={setTalentFiltersValue}
              filteredAdminView={!talentFiltersValue.member_view}
              setSelectedItems={setSelectedTalentPeople}
              talentPeople={companies}
              disabled={false}
            />
          )
        } else {
          return (
            <Filters
              teamSlug={company_slug}
              setFilters={setFilters}
              filters={filters}
              disabled={companyDataIsLoading}
              // Need to pass condition for portfolio filters and then impliment them there
              showPortfolioFilters={!!companyList?.portfolio}
              owningTeamSlug={companyList?.owning_team?.slug}
            />
          )
        }
      } else {
        if (companyList?.is_talent) {
          return (
            <div>
              <div className="flex flex-wrap gap-2">{renderedTalentFilters}</div>
              <div className="flex gap-1 flex-wrap mt-2">{appliedTalentFilters}</div>
            </div>
          )
        } else if (companyList?.portfolio) {
          return (
            <Filters
              teamSlug={company_slug}
              setFilters={setFilters}
              filters={filters}
              disabled={companyDataIsLoading}
              showPortfolioFilters={true}
              owningTeamSlug={companyList?.owning_team?.slug}
            />
          )
        }
      }
    }
  }

  const showingAdminSideWidget = useMemo(() => {
    return (
      companies &&
      companies.length > 0 &&
      !!listSlug &&
      view === 'list' &&
      !companyList?.is_talent &&
      (listTypeData?.add_connections || (listTypeData?.activity && !companyList?.portfolio))
    )
  }, [companies, listSlug, view, listTypeData, companyList?.portfolio])

  const companiesRendered = useMemo(() => {
    const companyRow = (view: 'list' | 'grid') =>
      companies?.map((company) => {
        if (!company) return null
        return (
          <CompanyCard
            listSlug={listSlug!}
            listUuid={listUuid!}
            teamSlug={company_slug}
            companyList={companyList}
            key={`company-${company.uuid}`}
            company={company}
            canDelete={isAdminOrEmployee}
            view={view}
            refetch={() => reload()}
            companyListCtaType={
              companyList?.cta_type ? companyList?.cta_type : companyList?.list_type
            }
            isOwned={!!companyList?.owned}
            isPortfolio={!!companyList?.portfolio}
            memberViewToggle={!!talentFiltersValue.member_view}
            selectedItems={selectedTalentPeople}
            isAllCandidates={companyList?.is_all_candidates}
            setSelectedItems={setSelectedTalentPeople}
          />
        )
      })

    return (
      <>
        {view === 'grid' && <GridWrapper>{companyRow('grid')}</GridWrapper>}
        {view === 'list' && (
          <div className={cn({ 'max-w-[630px]': showingAdminSideWidget })}>
            {companyRow('list')}
          </div>
        )}
      </>
    )
  }, [companies, listSlug, company_slug, listUuid, view, selectedTalentPeople])

  let child

  if (showResultsLoading || (!companyList && listParam)) {
    child = (
      <div className="flex justify-center items-center flex-col">
        <Loading />
      </div>
    )
  } else if (!isAdminOrEmployee) {
    child = (
      <>
        {view === 'list' && (
          <div className="flex space-x-2">
            <WidthWrapper isTalent={companyList?.is_talent}>
              <AdvisorListView
                teamSlug={teamSlug}
                talentFilters={
                  listTypeData?.show_filters && companyList?.is_talent ? talentFiltersValue : null
                }
                filters={filters}
                view={view}
              />
            </WidthWrapper>
            {!companyList?.is_talent && (
              <div>
                <div className="w-80 flex-shrink-0 flex gap-4 flex-col">
                  {listTypeData?.add_connections && <ConnectionsWidget location="dashboard" />}
                  {listTypeData?.activity && (
                    <CommentsAndActivity
                      teamSlug={teamSlug}
                      attachableId={listUuid}
                      attachableType={'CompanyList'}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {view === 'grid' && (
          <AdvisorListView
            teamSlug={teamSlug}
            talentFilters={
              listTypeData?.show_filters && companyList?.is_talent ? talentFiltersValue : null
            }
            filters={filters}
            view={view}
          />
        )}
      </>
    )
  } else {
    child = (
      <div>
        {!companyList?.database_list_type && (
          <div className="px-2 py-1">
            <StatusCards
              teamSlug={teamSlug}
              refetch={reload}
              listSlug={listSlug}
              allCompaniesOrAllConnections={allCompanies}
              showItemsIsSyncing={
                !allCompanies &&
                (companyList?.status?.initial_index_stale ||
                  companyList?.status?.dynamic_stale ||
                  (!companyDataIsLoading &&
                    !listDataIsLoading &&
                    (companies ?? []).length === 0 &&
                    !companyList?.status?.initial_indexing_completed_at))
              }
            />
          </div>
        )}

        <div className="flex space-x-2">
          <div ref={parent} className={cn('w-full')}>
            {showAddItem && companyList?.is_talent && (
              <div className="mb-2">
                <AddCandidate
                  teamSlug={teamSlug}
                  listUuid={companyList.is_all_candidates ? 'all-candidates' : companyList.uuid}
                  reload={reload}
                  onClose={() => setShowAddItem(false)}
                  isTalent={true}
                />
              </div>
            )}
            {showAddItem && !companyList?.is_talent && (
              <div className="mb-2">
                <NewItemModal
                  modal={false}
                  listTypeData={listTypeData}
                  teamSlug={teamSlug}
                  onHide={() => {}}
                  reload={reload}
                  listUuid={companyList.uuid}
                  onDelete={() => history.push(`/${teamSlug}/lists`)}
                  onClose={() => setShowAddItem(false)}
                  onSaveComplete={(listItem) => {
                    reload()
                  }}
                />
              </div>
            )}
            {companiesRendered}
          </div>

          {showingAdminSideWidget && (
            <div>
              {companies && companies.length > 0 && (
                <div className="w-80 flex-shrink-0 flex gap-4 flex-col">
                  <ConnectionsWidget location="dashboard" />
                  <>
                    <CommentsAndActivity
                      teamSlug={teamSlug}
                      attachableId={listUuid}
                      attachableType={'CompanyList'}
                    />
                  </>
                </div>
              )}
            </div>
          )}
        </div>

        {hasNextPage && (
          <div className="flex justify-center	my-6">
            <CabalButton
              disabled={isFetchingNextPage}
              onClick={() => {
                if (hasNextPage) {
                  fetchNextPage()
                }
              }}
            >
              Load more
            </CabalButton>
          </div>
        )}
      </div>
    )
  }

  const exportList = useMutation(
    async () => {
      return await api.exportList(
        companyList?.uuid,
        listTypeData?.show_filters && companyList?.is_talent ? talentFiltersValue : filters,
      )
    },
    {
      onSuccess: () => {
        cabalToast({
          content: 'Your export will be emailed to you shortly',
          style: 'success',
        })
      },
    },
  )

  const exportListWithConnections = useMutation(
    async () => {
      return await api.exportList(companyList?.uuid!, filters, true)
    },
    {
      onSuccess: () => {
        cabalToast({
          content: 'Your export will be emailed to you shortly',
          style: 'success',
        })
      },
    },
  )

  const memoizedHeader = useMemo(
    () => (
      <PageHeader
        data={{
          title: listSlug ? companyList?.name : 'All Companies',
          description_parts: compact([
            companyList?.owning_team && (
              <Typography
                fontSize="14"
                fontWeight={400}
                color={'fog'}
                className="flex items-center pb-0.5"
              >
                <Avatar
                  size="16"
                  name={companyList?.owning_team.name}
                  src={companyList?.owning_team.logo_url}
                  className="mr-1 "
                />
                {companyList?.owning_team.name}
              </Typography>
            ),
            companyList?.dynamic && (
              <Typography fontSize="14" fontWeight={400} color={'fog'}>
                <CrmIcon crm={companyList.provider} /> Deals
              </Typography>
            ),
            companyList?.item_count !== undefined && (
              <Typography fontSize="14" fontWeight={400} color={'fog'} className="mr-1">
                {companyList!.item_count} items
              </Typography>
            ),
            companyList?.is_talent && (
              <Typography fontSize="14" fontWeight={400} color={'fog'} className="mr-1">
                Talent
              </Typography>
            ),
          ]),
          avatar_url:
            companyList && !companyList.owned ? companyList.owning_team.logo_url : undefined,
        }}
        actions={{
          share: isAdmin ? () => showModal(renderShareListModal, 'render_share_list_modal') : null,
          edit:
            companyList?.owned && isAdminOrEmployee
              ? () => {
                  showModal(renderEditListModal, 'render_edit_list_modal')
                }
              : null,
          addItem:
            listTypeData?.add_item && isAdminOrEmployee
              ? {
                  onClick: () => setShowAddItem(!showAddItem),
                  label: companyList?.is_talent ? 'Add People' : listTypeData.add_item.cta,
                }
              : companyList?.portfolio && companyList.owned && isAdminOrEmployee
              ? {
                  label: 'Add Company',
                  onClick: () =>
                    showModal(renderAddPortfolioCompany, 'render_add_portfolio_company'),
                }
              : null,
          exportLink: isAdminOrEmployee
            ? {
                onClick: () => exportList.mutate(),
                label: 'Export',
              }
            : undefined,
          exportWithConnectionsLink:
            isAdminOrEmployee && companyList?.list_type === 'companies'
              ? {
                  onClick: () => exportListWithConnections.mutate(),
                  label: 'Export with connections',
                }
              : undefined,
          others: (
            <div className="inline-block">
              <CabalButton
                variant="link"
                leftIcon={
                  view === 'list' ? (
                    <i className="far fa-grid fa-fw" />
                  ) : (
                    <i className="far fa-equals fa-fw" />
                  )
                }
                onClick={() => setView(view === 'list' ? 'grid' : 'list')}
              >
                {view === 'list' ? 'Grid' : 'List'}
              </CabalButton>
            </div>
          ),
        }}
        backUrl={user.team && user.team.slug === teamSlug ? `/${teamSlug}/lists` : `/${teamSlug}`}
        renderFilters={setRenderedFilters()}
      />
    ),
    [
      listTypeData,
      companyDataIsLoading,
      companyList,
      company_slug,
      filters,
      isAdminOrEmployee,
      listSlug,
      teamSlug,
      user.team,
      history,
      showAddItem,
      setShowAddItem,
      view,
      selectedTalentPeople,
    ],
  )

  if (companyListError?.response?.status == 403) {
    return (
      <EmptyState
        icon={<i className="fa fa-lock" />}
        heading="You don't have access to this list"
      />
    )
  }

  // This is the empty state just for admin view
  //  Empty state for members is in <AdvisorListView />
  const renderEmptyState = () => {
    if (
      companyList &&
      !companyDataIsLoading &&
      (companies ?? []).length === 0 &&
      isAdminOrEmployee
    ) {
      if (companyList.list_type == 'companies') {
        return (
          <EmptyState
            icon={<i className="fa-light fa-building" />}
            heading="No Companies"
            body={'Add companies to get started'}
            cta={
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => {
                  companyList.portfolio
                    ? showModal(renderAddPortfolioCompany, 'render_add_portfolio_company')
                    : showModal(renderEditListModal, 'render_edit_list_modal')
                }}
                className="mt-2"
              >
                Add Companies
              </CabalButton>
            }
          />
        )
      } else if (companyList.list_type == 'people' && companyList.is_talent) {
        return (
          <EmptyState
            icon={<i className="fa-light fa-user-magnifying-glass" />}
            heading="No Candidates"
            body={'Add candidates to get started'}
            cta={
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => setShowAddItem(!showAddItem)}
                className="mt-2"
              >
                Add Candidates
              </CabalButton>
            }
          />
        )
      } else if (companyList.list_type == 'people' && !companyList.is_talent) {
        return (
          <EmptyState
            icon={<i className="fa-light fa-user-magnifying-glass" />}
            heading="No People"
            body={'Add people to get started'}
            cta={
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => setShowAddItem(!showAddItem)}
                className="mt-2"
              >
                Add People
              </CabalButton>
            }
          />
        )
      } else if (companyList.list_type == 'personas') {
        return (
          <EmptyState
            icon={<i className="fa-light fa-address-card" />}
            heading="No Personas"
            body={'Add personas to get started'}
            cta={
              <CabalButton
                variant="primary"
                leftIcon={<i className="far fa-plus" />}
                onClick={() => showModal(renderEditListModal, 'render_edit_list_modal')}
                className="mt-2"
              >
                Add Personas
              </CabalButton>
            }
          />
        )
      }
    }
  }

  return (
    <>
      {memoizedHeader}
      {child}
      {showListSharedModal && (
        <ListSharedModal
          resolve={() => setShowListSharedModal(false)}
          list={companyList}
          sharedWith={listSharedWith!}
          currentTeamSlug={teamSlug}
        />
      )}
      {(companies ?? []).length > 0 &&
        ((companyList && companyList.list_type == 'companies') || list_id === 'companies') && (
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <a style={{ color: '#A7B4C7', fontSize: '12px' }} href="https://clearbit.com">
              Logos provided by Clearbit
            </a>
          </div>
        )}
      {renderEmptyState()}
    </>
  )
}

export default Companies
