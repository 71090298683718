import React, { useState } from 'react'

import { useMutation } from 'react-query'

import FacilitatedBy from 'containers/IntroRequestModal/components/FacilitatedBy'
import UsersInfo from 'containers/IntroRequestModal/components/UsersInfo'
import { IIntroRequest } from 'containers/RequestCenter/types/ITypes'
import CabalButton from 'global/CabalButton'
import { TextArea } from 'global/Input'
import Modal from 'global/Modal'
import Typography from 'global/Typography'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'

interface Props {
  onHide: () => void
  introRequestData: IIntroRequest
}

const ReminderModal: React.FC<Props> = ({ onHide, introRequestData }) => {
  const { requestor, target, facilitator } = introRequestData
  const [note, setNote] = useState('')
  const { mutate: remind, isLoading: isLoading } = useMutation({
    mutationFn: () =>
      callApi(api.remindIntroRequestV2, introRequestData.intro_request.secret_uuid, note),
    onSuccess: () => {
      cabalToast({ style: 'success', content: 'Reminder sent' })
      onHide()
    },
  })

  return (
    <Modal
      show
      header={`Reminder to ${target.name}`}
      onHide={onHide}
      centerActions={
        <CabalButton onClick={remind} disabled={isLoading}>
          Send reminder
        </CabalButton>
      }
      className="max-w-lg"
    >
      <UsersInfo requestor={requestor} target={target}></UsersInfo>
      <FacilitatedBy facilitator={facilitator} />
      <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
        Previous notes:
      </Typography>

      {introRequestData.note_to_requestable &&
        introRequestData.note_to_requestable?.length > 0 &&
        introRequestData.note_to_requestable?.map((note, index, array) => (
          <Typography
            component="p"
            fontSize="12"
            fontWeight={400}
            lineHeight="1.2"
            color="fog"
            className="italic py-2 ml-4"
            key={note.id}
          >
            {array.length > 1 &&
              note.created_at &&
              new Date(note.created_at).toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
              }) + ': '}
            {note.content}
          </Typography>
        ))}
      <Typography fontSize="12" fontWeight={400} lineHeight="1" className=" text-center">
        Add note
      </Typography>
      <TextArea
        value={note}
        className="w-full mt-1"
        rows={3}
        data-testid="request-talent-intro-modal-context"
        onChange={(e) => setNote(e.target.value)}
      />
    </Modal>
  )
}

export default ReminderModal
