import React, { createContext, useContext, useMemo, useState } from 'react'

import { useAutoAnimate } from '@formkit/auto-animate/react'

import { useInfiniteQuery, useQuery } from 'react-query'
import { ArrayParam, BooleanParam, StringParam } from 'use-query-params'

import NoResultsPage from 'containers/ListIndexView/Components/NoResultsPage'
import PortfolioAddWidget from 'containers/ListIndexView/PortfolioList/PortfolioAddWidget'
import PortfolioListAdminView from 'containers/ListIndexView/PortfolioList/PortfolioListAdminView'
import PortfolioListBulkActions from 'containers/ListIndexView/PortfolioList/PortfolioListBulkActions'
import PortfolioListCTAs from 'containers/ListIndexView/PortfolioList/PortfolioListCTAs'
import PortfolioListEmptyState from 'containers/ListIndexView/PortfolioList/PortfolioListEmptyState'
import PortfolioListFilters from 'containers/ListIndexView/PortfolioList/PortfolioListFilters'
import PortfolioListHeader from 'containers/ListIndexView/PortfolioList/PortfolioListHeader'
import PortfolioListMemberView from 'containers/ListIndexView/PortfolioList/PortfolioListMemberView'
import {
  IPortfolioCompany,
  PortfolioFilterTypes,
  PortfolioListContextProps,
} from 'containers/ListIndexView/PortfolioList/types'
import { noFilters, useScrollToElement } from 'containers/ListIndexView/hooks'
import { StickyHeader } from 'containers/ListIndexView/style'
import { useAccessControl } from 'global/AccessControl'
import CabalButton from 'global/CabalButton'
import Loading from 'global/Loading'
import PageWrapper from 'global/PageWrapper'
import { useTeamSlug } from 'store/hooks'
import SkeletonDataCard from 'ui-components/SkeletonDataCard'

import api, { callApi } from 'utils/api'
import { useQueryParamsWithSettings } from 'utils/hooks/useQueryParamsWithSettings'
import useScrollToEl from 'utils/hooks/useScrollToEl'
import { CompanyListBlueprint } from 'utils/types'

export const PortfolioListContext = createContext<PortfolioListContextProps | undefined>(undefined)

export const usePortfolioListContext = () => {
  const context = useContext(PortfolioListContext)
  if (context === undefined) {
    throw new Error('usePortfolioListContext must be used within a PortfolioListProvider')
  }
  return context
}

interface Props {
  companyListData: CompanyListBlueprint
  refetchCompanyList: () => void
}

const PortfolioList: React.FC<Props> = ({ companyListData, refetchCompanyList }) => {
  const defaultView = 'grid'
  const teamSlug = useTeamSlug()
  const { isAdminOrEmployee } = useAccessControl(teamSlug)
  const [view, setView] = useState<'grid' | 'list'>(defaultView)
  const [selectedCompanies, setSelectedCompanies] = useState<IPortfolioCompany[]>([])
  const [showAddWidget, setShowAddWidget] = useState(false)
  const scrollToAdd = useScrollToElement('portfolio-add-container')

  const [parent] = useAutoAnimate({
    duration: 300,
    easing: 'ease-in-out',
  })

  const queryParamConfigMap = useMemo(() => {
    return {
      search: ArrayParam,
      portfolio_stages: ArrayParam,
      member_view: BooleanParam,
      size: ArrayParam,
      tags: ArrayParam,
      sort: StringParam,
    }
  }, [])

  const [filters, setFilters_] = useQueryParamsWithSettings(queryParamConfigMap)

  const { data: ownedCompanyTags } = useQuery(
    ['getInvestorTags', companyListData.owning_team.slug],
    () => callApi(api.getInvestorTags, companyListData.owning_team.slug!),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!companyListData.owning_team.slug,
    },
  )

  const {
    isLoading: companyDataIsLoading,
    data,
    refetch: refetchPortfolioCompaniesList,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [
      teamSlug,
      'opportunityData',
      companyListData?.uuid,
      companyListData?.status?.stale,
      companyListData?.item_count,
      filters,
    ],
    ({ pageParam: page = 1 }) =>
      callApi(api.portfolioGetCompanies, companyListData?.uuid, page, filters),
    {
      cacheTime: 0,
      getNextPageParam: (lastPage) => lastPage.pagination.next_page,
      getPreviousPageParam: (firstPage) => firstPage.pagination.prev_page,
      keepPreviousData: true,
    },
  )

  const portfolioCompaniesList: IPortfolioCompany[] = useMemo(() => {
    return (
      data?.pages?.flatMap((page) => {
        return page.portfolio_companies?.map((c: IPortfolioCompany) => c) || []
      }) || []
    )
  }, [data])

  const setFilters = (updatedFilters: PortfolioFilterTypes) => {
    setFilters_(updatedFilters)
  }

  const portfolioListEmpty = portfolioCompaniesList?.length === 0 && noFilters(filters)
  const filterResultEmpty = portfolioCompaniesList?.length === 0 && !noFilters(filters)
  const adminView = !!isAdminOrEmployee && !!companyListData.owned && !filters.member_view
  const memberView = !isAdminOrEmployee || !!filters.member_view
  const sharedListView = !companyListData.owned

  const mockMemberView = filters.member_view

  const contextValue = useMemo(() => {
    return {
      companyListData,
      refetchCompanyList,
      portfolioCompaniesList,
      refetchPortfolioCompaniesList,
      adminView,
      view,
      setView,
    }
  }, [
    companyListData,
    refetchCompanyList,
    portfolioCompaniesList,
    refetchPortfolioCompaniesList,
    adminView,
    view,
    setView,
  ])

  const count = companyListData.item_count > 16 ? 16 : companyListData.item_count

  return (
    <PortfolioListContext.Provider value={contextValue}>
      <PageWrapper title="Portfolio List">
        <StickyHeader>
          <div className="flex flex-col sm:flex-row justify-between gap-4 sm:items-center">
            <PortfolioListHeader />

            <div className="flex gap-6">
              <PortfolioListBulkActions
                selectedCompanies={selectedCompanies}
                ownedCompanyTags={ownedCompanyTags}
              />
              <PortfolioListCTAs
                onShowAddWidget={() => {
                  setShowAddWidget(!showAddWidget)
                  if (!showAddWidget) {
                    scrollToAdd()
                  }
                }}
              />
            </div>
          </div>

          <PortfolioListFilters
            filters={filters}
            setFilters={setFilters}
            ownedCompanyTags={ownedCompanyTags}
            setSelectedCompanies={setSelectedCompanies}
          />
        </StickyHeader>

        {companyDataIsLoading && <SkeletonDataCard view={view} itemCount={count} />}
        {!companyDataIsLoading && (
          <span>
            {/* Add widget */}
            <div ref={parent} id="portfolio-add-container">
              {showAddWidget && <PortfolioAddWidget onClose={() => setShowAddWidget(false)} />}
            </div>

            {/* Empty state */}
            {portfolioListEmpty && (
              <PortfolioListEmptyState
                adminView={adminView}
                onShowAddWidget={() => setShowAddWidget(!showAddWidget)}
              />
            )}

            {/* No results page */}
            {filterResultEmpty && <NoResultsPage />}

            {/* If admin */}
            {adminView && portfolioCompaniesList && (
              <PortfolioListAdminView
                selectedCompanies={selectedCompanies}
                setSelectedCompanies={setSelectedCompanies}
              />
            )}

            {/* If member or shared w company instance */}
            {(memberView || sharedListView) && portfolioCompaniesList && (
              <PortfolioListMemberView mockMemberView={mockMemberView} />
            )}

            {/* Common View More for all views */}
            {hasNextPage && (
              <div className="flex items-center justify-center my-8">
                <CabalButton
                  className="w-1/5"
                  working={isFetchingNextPage}
                  onClick={() => fetchNextPage()}
                >
                  View More
                </CabalButton>
              </div>
            )}
          </span>
        )}
      </PageWrapper>
    </PortfolioListContext.Provider>
  )
}

export default PortfolioList
