import React from 'react'

import { faker } from '@faker-js/faker'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Typography from 'global/Typography'
import PillV2 from 'ui-components/PillV2'
import Widget from 'ui-components/Widget'

import { Team } from 'utils/types'

const DummyPerson = () => {
  const randomName = faker.person.fullName()
  const avatar = faker.image.avatar()
  const randomDescription = faker.lorem.sentence()

  return (
    <div className="flex ml-1 py-2 gap-2">
      <Avatar src={avatar} name={randomName} size={'32'} />
      <div className="flex flex-col gap-1 w-full">
        <Typography fontSize="14" lineHeight={1}>
          {randomName}
        </Typography>
        <Typography color="fog" fontSize="12" lineHeight={1}>
          {randomDescription}
        </Typography>
      </div>
    </div>
  )
}

const DummyWidget = () => {
  const randomTitle = faker.lorem.words(2)
  return (
    <Widget
      title={
        <div className="w-32">
          <Typography fontSize="12" lineHeight={1}>
            {randomTitle}
          </Typography>
        </div>
      }
    >
      <DummyPerson />
      <DummyPerson />
      <DummyPerson />
    </Widget>
  )
}

interface Props {
  team: Team
}

const DummyHomePage = ({ team }: Props) => {
  return (
    <div className="blur-sm">
      {/* Header */}
      <div className="mt-4 flex justify-between">
        <div className="flex items-end">
          <div className="flex-shrink-0">
            <Avatar src={team?.logo} name={team?.name} size={'72'} />
          </div>
          <div className="flex flex-col ml-3">
            <div className="flex items-baseline gap-2">
              <Typography lineHeight={1.2} fontSize="36" fontWeight={600}>
                {team?.name}
              </Typography>
              <Typography fontSize="20" color="purple" lineHeight={1}>
                <i className="fa fa-badge-check fa-fw -mr-1" />
              </Typography>

              <Typography fontSize="20" color="fog" lineHeight={1} component="p">
                <i className="fab fa-x-twitter" />
              </Typography>
            </div>

            <Typography className="py-1 truncate max-w-lg" color="fog" fontSize="16" lineHeight={1}>
              This is the resource page for {team?.name}
            </Typography>
          </div>
        </div>
        <div className="flex flex-shrink-0 items-baseline">
          <CabalButton variant="link" leftIcon={<i className="far fa-pencil" />}>
            <Typography fontSize="12">Edit</Typography>
          </CabalButton>
          <CabalButton variant="link" leftIcon={<i className="far fa-arrow-up-from-bracket" />}>
            <Typography fontSize="12">Invite</Typography>
          </CabalButton>
        </div>
      </div>

      {/* Filters */}
      <div className="mt-10 flex flex-wrap gap-2">
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              All
            </Typography>
          }
          active={true}
          onClick={() => {}}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              Portfolio
            </Typography>
          }
          active={false}
          onClick={() => {}}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              Resources
            </Typography>
          }
          active={false}
          onClick={() => {}}
        />
        <PillV2
          title={
            <Typography fontSize="12" lineHeight={1}>
              Talent
            </Typography>
          }
          active={false}
          onClick={() => {}}
        />
      </div>

      <div className="mt-4 flex flex-col lg:flex-row gap-4">
        <div className="flex flex-col flex-1 gap-4 max-w-[630px]">
          <div className="flex flex-col gap-4">
            <DummyWidget />
            <DummyWidget />
            <DummyWidget />
          </div>
        </div>
        <div className="lg:w-80 flex-shrink-0 flex flex-col gap-4">
          <DummyWidget />
          <DummyWidget />
        </div>
      </div>
    </div>
  )
}

export default DummyHomePage
