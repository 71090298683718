import React from 'react'

import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { variant } from 'styled-system'

import Avatar from 'global/Avatar'
import CabalButton from 'global/CabalButton'
import Pill from 'global/Pill'
import Typography from 'global/Typography'
import { useCurrentUser } from 'store/hooks'
import DataCardV2 from 'ui-components/DataCardV2'

import api, { callApi } from 'utils/api'
import { cn } from 'utils/styles'
import { AdvisorModel, Team } from 'utils/types'

interface Props {
  team: Team
  pending?: boolean
  advisor?: AdvisorModel
  view?: 'list' | 'grid'
}

const CompanyInfo: React.FC<Props> = ({ team, advisor, pending = false, view }) => {
  const history = useHistory()

  const description = []

  const candidate = advisor?.role === 'candidate'
  const { user } = useCurrentUser()

  if (!team) return <div />

  if (candidate) {
  } else {
    if (team.shared_connections) {
      description.push(
        <Typography fontSize="12">
          <i className="far fa-check mr-1"></i>
          Connections shared
        </Typography>,
      )
    } else {
      description.push(
        <Typography fontSize="12" color="gold">
          <i className="far fa-exclamation-triangle mr-1"></i>
          Connections not shared
        </Typography>,
      )
    }

    if (view === 'list') {
      description.push(
        <Typography fontSize="12">
          <i className="far fa-list mr-1"></i>
          {team.enable_asks && team.public_list_count ? team.public_list_count : 0} Lists
        </Typography>,
      )
    }
  }

  const generateInviteLink = useMutation(
    ({ ownerUuid, emails, steps, note }) => {
      return callApi(
        api.copyCandidatesInviteLink,
        'all-candidates',
        [],
        ownerUuid,
        emails,
        steps,
        note,
        team.slug,
        false,
      )
    },
    {
      onSuccess: ({ link }) => {
        history.push(link)
      },
    },
  )

  const onCompanyClick = () => {
    if (candidate && !advisor?.candidate_uuid) {
      const note = ''
      const ownerUuid = advisor.owner_uuid
      const emails = [user.email]
      const steps = {
        welcome: true,
        connections: false,
        personal_info: true,
        talent_profile: true,
      }
      generateInviteLink.mutate({ ownerUuid, emails, steps, note })
    } else if (!pending) {
      window.location.href = `/${team.slug}`
    }
  }

  const yourTeam = team.user_role === 'member' || team.user_role === 'admin'

  return (
    <div className="relative">
      <DataCardV2
        onClick={onCompanyClick}
        title={team.name}
        description={description}
        descriptionBottom={
          <>
            <Typography fontSize="12">
              <i className="far fa-list mr-1"></i>
              {team.enable_asks && team.public_list_count ? team.public_list_count : 0} Lists
            </Typography>
          </>
        }
        view={view}
        avatar={
          <Avatar
            name={team.name}
            src={team.logo}
            className="flex-shrink-0 rounded"
            size={view === 'grid' ? '72' : '32'}
          />
        }
        cta={
          view === 'list' ? (
            <div className="-mx-2">
              {pending ? (
                <CabalButton padding="0" variant={`link`} disabled={pending}>
                  Request Pending
                </CabalButton>
              ) : (
                <CabalButton
                  variant={`link`}
                  disabled={pending}
                  rightIcon={<i className={`fa fa-chevron-right`} />}
                  onClick={onCompanyClick}
                  padding="0"
                >
                  View
                </CabalButton>
              )}
            </div>
          ) : (
            <div>
              {pending ? (
                <CabalButton className="w-full" variant={'primary'} disabled={pending}>
                  Request Pending
                </CabalButton>
              ) : (
                <CabalButton
                  variant={'primary'}
                  disabled={pending}
                  rightIcon={<i className={`fa fa-chevron-right`} />}
                  onClick={onCompanyClick}
                  className="w-full"
                >
                  View
                </CabalButton>
              )}
            </div>
          )
        }
      />
      {yourTeam && (
        <div
          className={cn(
            'absolute top-4 bottom-0 z-20 h-2',
            view === 'grid' ? 'right-4' : 'right-16',
          )}
        >
          <Typography color="fog">
            <i className="far fa-users"></i>
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CompanyInfo
