import React, { useEffect, useState } from 'react'

import { useMutation, useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useCrmStatus from 'components/CrmStatus'
import { BorderCard } from 'containers/CompanyPage/TeamSharedListsWidget'
import CrmSelectModal from 'containers/CompanyPage/TeamSharedListsWidget/CrmSelectModal'
import DropDownMenu from 'global/DropDownMenu'
import { useModal } from 'global/Modal'
import Typography from 'global/Typography'
import { useCurrentUser, useTeam, useTeamSlug } from 'store/hooks'
import { cabalToast } from 'ui-components/Toast'

import api, { callApi } from 'utils/api'
import { CompanyListBlueprint } from 'utils/types'

interface Props {
  companyLists?: CompanyListBlueprint[]
  refetch: () => void
}

const EmptyStates = ({ companyLists, refetch }: Props) => {
  const teamSlug = useTeamSlug()
  const { team } = useTeam(teamSlug)
  const { user } = useCurrentUser()
  const { team: userTeam } = useTeam(user.team?.slug)
  const history = useHistory()
  const { showModal } = useModal()
  const { salesforceConnected, hubspotConnected } = useCrmStatus(user.team?.slug)
  const [sharedLists, setSharedLists] = useState({
    crmConnectedList: false,
    salesCompaniesList: false,
    personasList: false,
    jobsList: false,
  })

  const getListsQuery = useQuery(
    ['getCompanyLists', userTeam?.slug],
    () => callApi(api.getCompanyLists, userTeam!.slug),
    {
      enabled: !!userTeam,
    },
  )

  const allLists = (getListsQuery.data?.company_lists || []).filter(
    (l) => !l.portfolio && !!l.owned,
  )

  const { mutate: updateCompanyListAccess, isLoading: isAddingAccess } = useMutation(
    ['updateCompanyListAccess'],
    (list: CompanyListBlueprint) =>
      callApi(api.addListAccessors, user.team!.slug, list.uuid, {
        accessors: [{ accessor_type: 'Team', accessor_uuid: team.slug }],
        notify: true,
        message: '',
      }),
    {
      onSuccess: (_, list) => {
        refetch()
        cabalToast({
          content: `Success! ${list.name} shared with ${team?.name}. The team has been notified`,
          style: 'success',
        })
      },
    },
  )

  useEffect(() => {
    if (companyLists) {
      const newListTypes = {
        crmConnectedList: false,
        salesCompaniesList: false,
        personasList: false,
        // jobsList: false,
      }

      companyLists.forEach((list) => {
        if (list.dynamic) {
          newListTypes.crmConnectedList = false
        } else if (list.list_type === 'companies') {
          newListTypes.salesCompaniesList = true
        } else if (list.list_type === 'personas') {
          newListTypes.personasList = true
        }
        // } else if (list.list_type === 'jobs') {
        //   newListTypes.jobsList = true
        // }
      })

      setSharedLists(newListTypes)
    }
  }, [companyLists, salesforceConnected, hubspotConnected])

  const renderCrmModal = (resolve) => (
    <CrmSelectModal onHide={() => resolve()} teamSlug={teamSlug} />
  )

  const getCrmClick = () => {
    if (salesforceConnected || hubspotConnected) {
      return salesforceConnected
        ? history.push(`/share-list/${teamSlug}/salesforce`)
        : history.push(`/share-list/${teamSlug}/hubspot`)
    } else {
      showModal(renderCrmModal, 'render_crm_modal')
    }
  }

  const newListOption = {
    label: (
      <Typography>
        <i className="far fa-plus pr-1"></i> Add New List
      </Typography>
    ),
    onSelect: () => history.push(`/share-list/${teamSlug}/manual`),
  }

  const getEmptyStateValues = (key: string) => {
    switch (key) {
      case 'crmConnectedList':
        return {
          title: 'Share CRM List',
          leftElement:
            salesforceConnected || hubspotConnected ? (
              <i className="far fa-plus"></i>
            ) : (
              <>
                <i className="fab fa-salesforce"></i>
                <i className="fab fa-hubspot"></i>
              </>
            ),
          options:
            allLists
              .filter((l) => l.dynamic)
              .map((l) => ({
                label: `${l.name} - ${l.item_count} items`,
                onSelect: () => updateCompanyListAccess(l),
              })) || [],
          cta: () => getCrmClick(),
          ctaElement: <Typography color="purple">Connect</Typography>,
        }
      case 'salesCompaniesList':
        return {
          title: 'Share Accounts List',
          options:
            allLists
              .filter((l) => l.list_type === 'companies')
              .map((l) => ({
                label: `${l.name} - ${l.item_count} items`,
                onSelect: () => updateCompanyListAccess(l),
              })) || [],
          cta: () => history.push(`/share-list/${teamSlug}/manual`),
        }
      case 'personasList':
        return {
          title: 'Share Personas List',
          options:
            allLists
              .filter((l) => l.list_type === 'personas')
              .map((l) => ({
                label: `${l.name} - ${l.item_count} items`,
                onSelect: () => updateCompanyListAccess(l),
              })) || [],
          cta: () => history.push(`/share-list/${teamSlug}/personas`),
        }
      case 'jobsList':
        return {
          title: 'Share Jobs List',
          options:
            allLists
              .filter((l) => l.list_type === 'jobs')
              .map((l) => ({
                label: `${l.name} - ${l.item_count} items`,
                onSelect: () => updateCompanyListAccess(l),
              })) || [],
        }
      default:
        return { title: 'Unknown List', description: 'No description available' }
    }
  }

  return (
    <>
      {Object.entries(sharedLists).map(([key, value]) => {
        if (!value) {
          const { title, options, cta, leftElement, ctaElement } = getEmptyStateValues(key)

          if (key === 'jobsList' && options && options.length === 0) return <></>

          let allOptions = options || []

          if (key !== 'crmConnectedList') {
            allOptions = allOptions.concat(newListOption)
          }

          return (
            <div key={key} className="company-list-item empty-state">
              <BorderCard
                onClick={options && options.length > 0 ? undefined : cta}
                className="flex rounded-xl justify-between items-center px-3 py-1 cursor-pointer"
              >
                <div className="flex items-center gap-2">
                  <Typography component="p" fontSize="12" lineHeight={1.2} color="rain">
                    {leftElement || <i className="far fa-plus"></i>}
                  </Typography>
                  <Typography component="p" fontSize="12" lineHeight={1.2} color="rain">
                    {title}
                  </Typography>
                </div>
                <div>
                  {options && options.length > 0 && (
                    <DropDownMenu
                      menuItems={allOptions}
                      trigger={
                        <Typography color="rain" fontSize="12" className="px-2 -mr-1">
                          Select <i className="far fa-chevron-down" />
                        </Typography>
                      }
                    />
                  )}
                  {options && options.length === 0 && (
                    <Typography
                      color="rain"
                      fontSize="12"
                      component="p"
                      lineHeight={2}
                      onClick={cta}
                    >
                      {ctaElement || <i className="far fa-pencil" />}
                    </Typography>
                  )}
                </div>
              </BorderCard>
            </div>
          )
        }
        return null
      })}
    </>
  )
}

export default EmptyStates
