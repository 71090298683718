import React from 'react'

import Skeleton from 'react-loading-skeleton'

import { StyledTd } from 'containers/RequestCenter/BasicTable'

interface Props {
  rowCount: number
}

const SkeletalRows = ({ rowCount = 10 }: Props) => {
  return Array.from({ length: rowCount }).map((_, index) => (
    <tr key={index}>
      <StyledTd>
        <span className="flex items-center gap-2">
          <Skeleton width={24} height={24} circle />
          <Skeleton width={140} height={20} />
        </span>
      </StyledTd>
      <StyledTd>
        <Skeleton width={100} height={20} />
      </StyledTd>
      <StyledTd>
        <Skeleton width={160} height={20} />
      </StyledTd>
      <StyledTd>
        <Skeleton width={80} height={20} />
      </StyledTd>
      <StyledTd>
        <Skeleton width={60} height={20} />
      </StyledTd>
      <StyledTd>
        <Skeleton width={90} height={20} />
      </StyledTd>
    </tr>
  ))
}

export default SkeletalRows
